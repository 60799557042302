<template>
    <v-container style="min-height: 90vh" :class="{wrapper: cartItems.length === 0,  cart: cartItems.length !== 0}">
        <v-alert type="warning" v-if="warning">
            ممکن است برخی از آیتم های فاکتور پیشین دچار تغییر شده باشند، لطفا بررسی کنید.
        </v-alert>
        <div class="wrapper" align="center" v-if="cartItems.length === 0">
            <img class="empty-cart-img ma-12" src="../../assets/images/empty-card.png"/>
            <p class="text-large mr-12 pr-12">{{ $vuetify.lang.t('$vuetify.emptyCart') }}</p>
        </div>
        <Cart v-else></Cart>
    </v-container>
</template>

<script>
    import Cart from "../../components/cart/Cart";

    export default {
        created() {
            if (this.$route.params.hasOwnProperty('warning')) {
                this.warning = this.$route.params.warning;
            }
        },
        components: {
            Cart
        },
        data() {
            return {
                warning: false
            }
        },
        computed: {
            cartItems() {
                return this.$store.getters.getCartItems;
            },
        }
    }
</script>


<!--<template>-->
<!--    <v-container style="min-height: 90vh" :class="{wrapper: cartItems.length === 0,  cart: cartItems.length !== 0}">-->
<!--        <div class="wrapper" v-if="cartItems.length === 0">-->
<!--            <img class="empty-cart-img" src="../../assets/emptyCart.svg"/>-->
<!--            <p>{{ $vuetify.lang.t('$vuetify.emptyCart') }}</p>-->
<!--        </div>-->

<!--        <div v-else>-->
<!--            <v-card-->
<!--                    flat-->
<!--                    border-bottom-->
<!--                    class="mx-auto my-5"-->
<!--                    v-for="(item, index) in cartItems"-->
<!--                    :key="index"-->
<!--            >-->
<!--                    <v-container>-->
<!--                        <v-row align="center">-->
<!--                            <v-col>-->
<!--                                <v-list-item>-->
<!--                                    <v-list-item-avatar color="grey" tile style="border-radius: 5px;">-->
<!--                                        <img src="../../assets/origami.svg"/>-->
<!--                                    </v-list-item-avatar>-->
<!--                                    <v-list-item-content>-->
<!--                                        <v-list-item-title><div>{{ item.title }}</div></v-list-item-title>-->
<!--                                    </v-list-item-content>-->
<!--                                </v-list-item>-->
<!--                            </v-col>-->

<!--                            <v-col>-->
<!--                                <v-list-item>-->
<!--                                    <v-list-item-content>-->
<!--                                        <v-list-item-title class="font-weight-bold"><div>{{ item.price }}<v-icon size="20" color="#000">mdi-currency-usd</v-icon></div></v-list-item-title>-->
<!--                                    </v-list-item-content>-->
<!--                                </v-list-item>-->
<!--                            </v-col>-->

<!--                            <v-col>-->
<!--                                <v-list-item>-->
<!--                                    <v-icon @click="$store.dispatch('increaseQuantity', item)" small>mdi-plus</v-icon>-->
<!--                                    <span class="mx-2 font-weight-bold">{{ item.qty }}</span>-->
<!--                                    <v-icon @click="$store.dispatch('decreaseQuantity', item)" small>mdi-minus</v-icon>-->
<!--                                </v-list-item>-->
<!--                            </v-col>-->

<!--                            <v-col>-->
<!--                                <v-list-item>-->
<!--                                    <v-row align="center" justify="end">-->
<!--                                        <v-icon class="mr-2" color="error" @click="$store.dispatch('removeItem', item)">fas-->
<!--                                            fa-trash-alt-->
<!--                                        </v-icon>-->
<!--                                    </v-row>-->
<!--                                </v-list-item>-->
<!--                            </v-col>-->
<!--                        </v-row>-->
<!--                    </v-container>-->
<!--                <v-divider></v-divider>-->
<!--            </v-card>-->
<!--            <v-row class="mt-12 flex-grow-1">-->
<!--                <v-col cols="12" sm="6" md="2">-->
<!--                    <v-text-field-->
<!--                            v-model="voucherCode"-->
<!--                            label="کد تخفیف"-->
<!--                            @keyup="validateVoucher"-->
<!--                    ></v-text-field>-->
<!--                </v-col>-->
<!--                <v-col cols="12" sm="6" md="3" class="mt-8" v-if="voucherCode">-->
<!--                    <p class="text-center" :style="[validVoucher ? {color: 'green'} : {color: 'red'}]">{{-->
<!--                        message }}</p>-->
<!--                </v-col>-->

<!--                <v-spacer></v-spacer>-->
<!--                <v-col cols="12" sm="6" md="4" class="d-flex">-->
<!--                    <v-text-field-->
<!--                            :value="`$ ${calculateSum}`"-->
<!--                            label="جمع کل:"-->
<!--                            readonly-->
<!--                            outlined-->
<!--                    ></v-text-field>-->
<!--                    <v-text-field-->
<!--                            class="mx-3"-->
<!--                            :value="`$ ${finalPrice}`"-->
<!--                            label="مبلغ نهایی:"-->
<!--                            readonly-->
<!--                            outlined-->
<!--                    ></v-text-field>-->
<!--                </v-col>-->
<!--            </v-row>-->
<!--        </div>-->
<!--        <v-row v-if="cartItems.length != 0" class="align-end mb-10">-->
<!--            <v-col cols="12" sm="6" md="2">-->
<!--                <v-btn-->
<!--                        color="primary"-->
<!--                        outlined-->
<!--                        @click="$router.push({name: 'products'})"-->
<!--                >-->
<!--                    انتخاب سایر پکیج ها-->
<!--                </v-btn>-->
<!--            </v-col>-->
<!--            <v-spacer></v-spacer>-->
<!--            <v-col cols="12" sm="6" md="2">-->
<!--                <v-btn-->
<!--                        @click="sendOrder"-->
<!--                        color="primary"-->
<!--                        dark-->
<!--                >-->
<!--                    انتقال به درگاه پرداخت-->
<!--                </v-btn>-->
<!--            </v-col>-->
<!--        </v-row>-->
<!--    </v-container>-->
<!--</template>-->

<!--<script>-->
<!--    export default {-->
<!--        name: 'clientCart',-->
<!--        data() {-->
<!--            return {-->
<!--                voucherCode: "",-->
<!--                validVoucher: false,-->
<!--                message: "",-->
<!--                voucher: {}-->
<!--            }-->
<!--        },-->
<!--        computed: {-->
<!--            cartItems() {-->
<!--                return this.$store.getters.getCartItems;-->
<!--            },-->
<!--            calculateSum() {-->
<!--                this.$store.dispatch('calculateSum', this.cartItems);-->
<!--                return this.$store.getters.getSumPrice;-->
<!--            },-->
<!--            finalPrice() {-->
<!--                if (this.validVoucher) {-->
<!--                    let amount = this.$store.getters.getSumPrice;-->
<!--                    return (amount - this.voucherPrice);-->
<!--                } else {-->
<!--                    return this.$store.getters.getSumPrice;-->

<!--                }-->
<!--            }-->
<!--        },-->
<!--        created() {-->

<!--        },-->
<!--        methods: {-->
<!--            validateVoucher(event) {-->
<!--                let code = event.keyCode;-->
<!--                console.log(code)-->
<!--                if ((code >= 48 && code <= 57) || (code >= 65 && code <= 90) || (code >= 96 && code <= 105) || (code == 109) || (code == 189)) {-->

<!--                    clearTimeout(this.voucherTimer);-->

<!--                    this.voucherTimer = setTimeout(() => {-->
<!--                        if (this.voucherCode && this.voucherCode.length > 3) {-->
<!--                            this.$store.dispatch('validateVoucher', {-->
<!--                                'voucher': this.voucherCode-->
<!--                            }).then(resp => {-->
<!--                                if (resp.data.status == 'invalid') {-->
<!--                                    this.validVoucher = false;-->
<!--                                    this.message = resp.data.msg;-->
<!--                                } else if (resp.data.status == 'valid') {-->
<!--                                    this.message = resp.data.msg;-->
<!--                                    this.validVoucher = true;-->
<!--                                    this.voucher = resp.data;-->
<!--                                    // self.calculateSubTotal();-->
<!--                                    console.log('valid')-->
<!--                                }-->
<!--                            });-->
<!--                        }-->
<!--                    }, 500);-->
<!--                } else {-->
<!--                    console.log('Invalid Btn');-->
<!--                }-->
<!--            },-->
<!--            voucherPrice: function () {-->
<!--                let amount = this.$store.getters.getSumPrice;-->
<!--                let percent = this.voucher.percent / 100;-->
<!--                let value = this.voucher.value;-->
<!--                let price = (percent * amount) + value;-->
<!--                return price.toFixed(2);-->
<!--            },-->
<!--            allData() {-->
<!--                const cart = this.$store.getters.getCartItems;-->

<!--                let products = [];-->
<!--                let data = {};-->
<!--                cart.forEach((item) => {-->
<!--                    products.push(-->
<!--                        {-->
<!--                            'id': item._id,-->
<!--                            'quantity': item.qty-->
<!--                        }-->
<!--                    );-->
<!--                });-->

<!--                data.products = products;-->
<!--                data.voucher = this.voucherCode;-->
<!--                data.totalPrice = this.$store.getters.getSumPrice;-->
<!--                data.finalPrice = this.finalPrice;-->
<!--                console.log('data', data);-->
<!--                return data;-->
<!--            },-->
<!--            sendOrder() {-->
<!--                this.$store.dispatch('sendOrder', this.allData()).then((data) => {-->
<!--                    const success_code = data.data.substring(data.data.search("=") + 1 );-->
<!--                    this.$router.replace({ name: 'assignconsult', query: {successful_payment: success_code} });-->
<!--                    this.$store.commit('emptyCart');-->
<!--                })-->
<!--            }-->
<!--        }-->
<!--    }-->
<!--</script>-->

<!--<style scoped>-->
<!--    .wrapper {-->
<!--        display: flex;-->
<!--        flex-direction: column;-->
<!--        justify-content: center;-->
<!--        align-items: center;-->
<!--    }-->

<!--    .empty-cart-img {-->
<!--        background-size: cover;-->
<!--        background-position: center;-->
<!--        height: 40vh;-->
<!--    }-->

<!--    .cart {-->
<!--        display: flex;-->
<!--        flex-direction: column;-->
<!--    }-->
<!--</style>-->