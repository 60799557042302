<template>
  <div>
    <v-card-text class="page-title d-block d-lg-none pt-2 pb-0 pr-0">{{ $route.meta.name }}</v-card-text>
    <v-breadcrumbs class="pb-0 mb-0 pt-3 mr-0 pr-0" :items="items"></v-breadcrumbs>
    <CartComponent discount="true" price="true" remove="true"></CartComponent>
    <v-card-actions>
      <v-row v-if="cartItems.length !== 0" class="align-end mb-10" justify="center" align="center">
        <v-col cols="12" sm="6" md="2">
          <v-btn
              color="primary"
              outlined
              rounded
              @click="$router.push({name: 'products'})"
          >
            {{$t('$vuetify.selectMore')}}
          </v-btn>
        </v-col>
        <!--<v-spacer></v-spacer>-->
        <!--<v-col cols="12" sm="6" md="2">-->
        <!--<v-btn-->
        <!--@click="sendOrder"-->
        <!--color="primary"-->
        <!--dark-->
        <!--&gt;-->
        <!--انتقال به درگاه پرداخت-->
        <!--</v-btn>-->
        <!--</v-col>-->
      </v-row>
    </v-card-actions>
  </div>
</template>

<script>
import CartComponent from "./CartComponent";
import {EventBus} from "@/event-bus";

export default {
    data() {
    return {
      items: [
        {
          text: this.$t('$vuetify.ClientNavItem.dashboard'),
          disabled: false,
          to: `/${this.$route.params.lang}/panel/dashboard`,
        },
        {
          text: this.$t('$vuetify.ClientNavItem.basket'),
          disabled: true,
          to: 'order',
        }
      ]
    }
  },
  components: {
    CartComponent
  },
  computed: {
    cartItems() {
      return this.$store.getters.getCartItems;
    }
  },
  methods: {
    sendOrder() {
      EventBus.$emit('sendOrderEvent');
    }
  }
}
</script>

<style scoped>

</style>